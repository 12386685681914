<template>
  <div class="content">
    <div class="zsjg">知识加工投诉处理</div>
    <div class="shzt">
      <span>审核状态：</span>
      <span>
        <el-select v-model="value" @change="init(pageSize, 0)" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </span>
    </div>
    <!-- 表格 -->
    <div>
      <el-table
        :data="tableData"
        stripe
        :row-class-name="tableRowClassName"
        :header-cell-style="{
          background: '#d8d8d8ee',
          color: '#666',
          width: '100%',
        }"
        border
        style="width:96%;font-size:17px;margin:auto; "
      >
        <el-table-column prop="taskName" label="任务名称"></el-table-column>
        <el-table-column prop="childTaskName" label="子任务名称"></el-table-column>
        <el-table-column label="加工成果">
          <template slot-scope="scope">
            <!-- slot-scope="scope" -->
            <!-- scope.row.id,scope.row.tasktype,scope.row.jgFzrAndTaskId -->
            <el-link type="primary" @click="see(scope.row)">查看</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="creatime" label="投诉时间"></el-table-column>
        <el-table-column prop="complaintStatus" label="审核状态">
          <template slot-scope="scope">
            <span>{{scope.row.complaintStatus}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="success" @click="success(scope.row.p_id)">查看</el-button>
            <el-button
              v-if="scope.row.complaintStatus=='待审核'"
              type="primary"
              @click="adopt('通过申诉',scope.row.complaintStatus,scope.row.p_id,scope.row.creatime,scope.row,scope.row.taskType )"
            >通过</el-button>
            <el-button
              v-if="scope.row.complaintStatus=='待审核'"
              type="danger"
              @click="adopt('驳回申诉',scope.row.complaintStatus,scope.row.p_id,scope.row.creatime,scope.row,scope.row.taskType  )"
            >驳回</el-button>
            <el-button v-if="scope.row.complaintStatus!='待审核'" type="info">通过</el-button>
            <el-button v-if="scope.row.complaintStatus!='待审核'" type="info">驳回</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="prev">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="pageSize"
          :total="total"
          @prev-click="prevPage"
          @next-click="prevPage"
          @current-change="prevPage"
        ></el-pagination>
      </div>
    </div>
    <!-- 投诉原因查看 -->
    <div class="tsyy" v-if="tsyy">
      <div class="fontsize">投诉原因查看：</div>
      <el-form label-position="right" label-width="80px" class="demo-ruleForm fontsize">
        <el-form-item label="原因:">
          <span class="reason huanghang">{{reason}}</span>
        </el-form-item>
        <el-form-item label="图片展示:" class="fontsize">
          <div class="width_image" v-if="image!=undefined&&image!=''">
            <el-image
              class="DT_image"
              v-for="(img,index) in image"
              :key="index"
              :src="img"
              :preview-src-list="image"
            ></el-image>
            <span
              style="font-size: 12px;font-family: Microsoft YaHei;font-weight: 400;color: #000000;"
            >图片点击可放大查看</span>
          </div>
          <div
            v-else
            style="font-size: 12px;font-family: Microsoft YaHei;font-weight: 400;color: #000000;"
          >暂无图片</div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tankuang" v-if="shensu">
      <div class="shensu">
        <div class="heyue_top">
          <span class="yin1" @click="close"></span>
        </div>
        <!-- 同意申诉、驳回申诉 -->
        <div class="tyss">{{appeal}}</div>
        <div class="yuanyin">
          <span class="xinhao">
            <span style="color:red;">*</span>
            <span>原因：</span>
          </span>
          <textarea style="resize: none;font-size:14px;" v-model="textarea"></textarea>
        </div>
        <div class="bottoms">
          <el-button type="primary" @click="FS(appeal)">发送</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateType, showTimeFormat } from "@/js/common/index.js";
import {
  DELETE,
  QUERY,
  UPDATES,
  UPDATE,
  QUERYED,
  INSERT
} from "@/services/dao.js";
import {
  updatedownload,
  formatZero,
  getTimeFormat
} from "@/js/common/Resoures";

export default {
  data() {
    return {
      options: [
        {
          value: "",
          label: "全部"
        },
        {
          value: "1",
          label: "待审核"
        },
        {
          value: "2",
          label: "已通过"
        },
        {
          value: "3",
          label: "被驳回"
        }
      ],
      value: "",
      tableData: [
        {
          p_id: 1,
          taskName: "",
          childTaskName: "",
          creatime: "",
          complaintStatus: ""
        }
      ],
      reason: "", ///投诉原因
      tsyy: false, //投诉原因
      textarea: "", //投诉原因、、发送
      textarea_id: 0, //投诉id
      shensu: false, ///是否显示投诉框
      appeal: "", ///申诉情况
      // image: "",
      image: [
        "/resources/trading/upload/JPG/IMG_3002(20210509-033725)_1620705447735.JPG",
        "/resources/trading/upload/JPG/IMG_2999(20210508-155512)_1620705450192.JPG",
        "/resources/trading/upload/png/cha_1620705452494.png"
      ], //图片
      total: 500, //总条数
      pageSize: 10, //条数
      pageNum: 50, //页数
      p_id: 0, //存储传递得值
      fzrId: [], //任务发布者
      taskTypeTow: "" //任务分类
      // API: ""
    };
  },
  created() {
    // this.API = this.api.LoginURL.concat();
    this.init(this.pageSize, 0);
  },
  methods: {
    // 查看
    see(row) {
      this.$router.push({
        path: "/Achievement",
        query: {
          id: row.taskId ,
          type:row.taskType*1-1, //任务负责人
          fzrid: row.fzrId, //负责人ID
          state: 2 //0:发布者，1：负责人,2：后台管理
        }
      });
    },
    prevPage(val) {
      this.pageNum = val - 1;
      this.init(this.pageSize, this.pageSize * this.pageNum);
    },
    async init(limit, offset) {
      this.tableData = []; //清空
      let info = null;
      if (this.value == "" || this.value == null) {
        info = await QUERYED(
          "post",
          "",
          "jgComplain_ChildTask(limit: " +
            limit +
            ", offset: " +
            offset +
            "){creatime id taskType taskId  childTaskMoney complaintImg fzrId jgUserId  complaintsReasons p_id taskName childTaskName complaintStatus } jgComplain_ChildTask_aggregate {     aggregate {       count     }   }"
        );
      } else {
        info = await QUERYED(
          "post",
          "",
          "jgComplain_ChildTask(limit: " +
            limit +
            ", offset: " +
            offset +
            ", where: {complaintStatus: {_eq:" +
            this.value +
            " }}){creatime id taskType taskId   childTaskMoney fzrId jgUserId  complaintImg complaintsReasons p_id taskName childTaskName complaintStatus } jgComplain_ChildTask_aggregate {     aggregate {       count     }   }"
        );
      }
      for (let i = 0; i < info.data.jgComplain_ChildTask.length; i++) {
        info.data.jgComplain_ChildTask[i].creatime = dateType(
          Number(info.data.jgComplain_ChildTask[i].creatime)
        ); //时间转换
        if (info.data.jgComplain_ChildTask[i].complaintStatus == 1) {
          info.data.jgComplain_ChildTask[i].complaintStatus = "待审核";
        }
        if (info.data.jgComplain_ChildTask[i].complaintStatus == 2) {
          info.data.jgComplain_ChildTask[i].complaintStatus = "已通过";
        }
        if (info.data.jgComplain_ChildTask[i].complaintStatus == 3) {
          info.data.jgComplain_ChildTask[i].complaintStatus = "被驳回";
        }
        // console.log(info.data.jgComplain_ChildTask[i].creatime)
      }
      this.tableData = info.data.jgComplain_ChildTask;
      this.total = info.data.jgComplain_ChildTask_aggregate.aggregate.count;
    },
    // 通过、驳回
    adopt(name, complaintStatus, id, creatime, fzrId, type) {
      // console.log("当前时间", showTimeFormat(new Date()));
      var mydate = new Date(creatime);
      console.log("截止时间", dateType(mydate.setDate(mydate.getDate() + 5)));
      if (
        showTimeFormat(new Date()) <=
        dateType(mydate.setDate(mydate.getDate() + 5))
      ) {
        this.taskTypeTow = type; //任务分类
        this.fzrId = fzrId; //投诉当前行的值
        this.textarea = ""; //清空
        this.textarea_id = id;
        if (complaintStatus == "待审核") {
          this.shensu = !this.shensu;
          this.appeal = name; ///显示申请状态
        }
      } else {
        this.$message({
          message: "请在五天内审核！",
          type: "warning"
        });
      }
    },
    //////通过投诉（发送）
    async FS(name) {
      // alert(this.textarea_id)
      if (this.textarea == "") {
        this.$message({
          message: "原因为必填！",
          type: "warning"
        });
      } else {
        let Xuigai = await UPDATE(
          "post",
          "",
          "update_jgComplain(where: {id: {_eq: " +
            this.textarea_id +
            "}}, _set: {complaintStatus : " +
            (name == "通过申诉" ? 2 : 3) +
            "}) { affected_rows  }"
        );
        if (Xuigai.data.update_jgComplain.affected_rows == 1) {
          if (name == "通过申诉") {
            this.TYXX(); //发送双方审核通过的消息
            this.KCZSB(); //扣除加工者的账单
            this.TYZD(); //生成加工者账单成功
          } else {
            ///驳回申诉
            this.BHXX(); ///驳回申诉的消息
          }
          this.$message({
            message: name + "成功！",
            type: "success"
          });
          this.shensu = false;
          this.init(this.pageSize, 0);
        } else {
          this.$message.error(name + "失败！");
        }
      }
    },
    //驳回申诉的消息
    async BHXX() {
      ///任务发布者
      let fbz = await INSERT(
        "post",
        "",
        " insert_jgMessage(objects: {messageType:6,userId : " +
          this.fzrId.fzrId +
          ",money: " +
          this.fzrId.childTaskMoney +
          ",complaintStatus : 3,userType: " +
          2 +
          ',taskName: "' +
          this.fzrId.childTaskName +
          '",reason: "' +
          this.textarea +
          '",taskTime : "' +
          new Date().getTime() +
          '",taskTypeTow : ' +
          this.taskTypeTow +
          "}) { affected_rows  }"
      );

      if (fbz.data.insert_jgMessage.affected_rows) {
        ///加工者
        let jgz = await INSERT(
          "post",
          "",
          " insert_jgMessage(objects: {messageType:6,userId : " +
            this.fzrId.jgUserId +
            ",complaintStatus : 3,userType: " +
            3 +
            ',taskName: "' +
            this.fzrId.childTaskName +
            '",reason: "' +
            this.textarea +
            '",taskTime : "' +
            new Date().getTime() +
            '",taskTypeTow : ' +
            this.taskTypeTow +
            "}) { affected_rows  }"
        );
        if (jgz.data.insert_jgMessage.affected_rows == 1) {
          console.log("双方消息发送成功！");
        }
      }
    },
    ////给任务发布者和加工者发送一条被投诉成功的消息(同意消息)
    async TYXX() {
      ///任务发布者
      let fbz = await INSERT(
        "post",
        "",
        " insert_jgMessage(objects: {messageType:6,userId : " +
          this.fzrId.fzrId +
          ",money: " +
          this.fzrId.childTaskMoney +
          ",complaintStatus : 2,userType: " +
          2 +
          ',taskName: "' +
          this.fzrId.childTaskName +
          '",reason: "' +
          this.textarea +
          '",taskTime : "' +
          new Date().getTime() +
          '",taskTypeTow : ' +
          this.taskTypeTow +
          "}) { affected_rows  }"
      );
      if (fbz.data.insert_jgMessage.affected_rows) {
        ///加工者
        let jgz = await INSERT(
          "post",
          "",
          " insert_jgMessage(objects: {messageType:6,userId : " +
            this.fzrId.jgUserId +
            ",complaintStatus : 2,userType: " +
            3 +
            ',taskName: "' +
            this.fzrId.childTaskName +
            '",reason: "' +
            this.textarea +
            '",taskTime : "' +
            new Date().getTime() +
            '",taskTypeTow : ' +
            this.taskTypeTow +
            "}) { affected_rows  }"
        );
        if (jgz.data.insert_jgMessage.affected_rows == 1) {
          console.log("双方消息发送成功！");
        }
      }
    },
    // 审核同意的账单
    async TYZD() {
      ////用户ID
      let ddata1 = await QUERYED(
        "post",
        "",
        "  WDALLBill(where: {userid: {_eq: " +
          this.fzrId.jgUserId +
          "}}) {  time  orderNum  id   }"
      );
      // 生成扣除加工者账单的订单编号
      let orderNum1 = "";
      let num1 = String(formatZero(this.fzrId.jgUserId, 7));
      /////生成订单编号
      if (ddata1.data.WDALLBill.length < 1) {
        orderNum1 = getTimeFormat(new Date()) + num1 + "001";
      } else {
        for (let i = 0; i < ddata1.data.WDALLBill.length; i++) {
          if (
            getTimeFormat(new Date()) ==
            getTimeFormat(ddata1.data.WDALLBill[i].time)
          ) {
            let sic = ddata1.data.WDALLBill[0].orderNum.substr(-3) * 1 + 1;
            orderNum1 =
              getTimeFormat(new Date()) + num1 + String(formatZero(sic, 3));
          } else {
            orderNum1 = getTimeFormat(new Date()) + num1 + "001";
          }
        }
      }
      ///this.fzrId.id 子任务ID  .getTime()
      let data2 = await INSERT(
        "post",
        "",
        '  insert_bill(objects: {type: 4, state: 0, time: "' +
          new Date() +
          '", userid: ' +
          this.fzrId.jgUserId +
          ', orderNum: "' +
          orderNum1 +
          '", orderid: ' +
          this.fzrId.id +
          ', Price: "' +
          this.fzrId.childTaskMoney +
          '", evaluate: "",classification:1}) {affected_rows}'
      );
      if (data2.data.insert_bill.affected_rows == 1) {
        console.log("扣除加工者知识币账单成功！");
      }
    },
    //扣除知识币成功
    async KCZSB() {
      ////用户ID
      let ddata1 = await QUERYED(
        "post",
        "",
        "  User(where: {id: {_eq: " + this.fzrId.jgUserId + "}}) {Money}"
      );
      ////修改加工者余额
      let Xuigai = await UPDATE(
        "post",
        "",
        "update_User(where: {id: {_eq: " +
          this.fzrId.jgUserId +
          "}}, _set: {Money : " +
          (Number(ddata1.data.User[0].Money) -
            Number(this.fzrId.childTaskMoney)) +
          "}) { affected_rows  }"
      );
      if (Xuigai.data.update_User.affected_rows == 1) {
        console.log("扣除加工者知识币成功！");
      }
    },
    //关闭弹框
    close() {
      this.shensu = false; //关闭
    },
    //查看
    async success(id) {
      console.log("this.p_id", this.p_id);
      console.log("id", id);
      this.image = [];
      if (this.p_id != id || id != this.p_id) {
        this.p_id = id; //赋值投诉ID
        this.tsyy = true; //显示投诉原因
        let info = null;
        ///通过ID查找原因
        info = await QUERYED(
          "post",
          "",
          "jgComplain_ChildTask(where: {p_id: {_eq:" +
            this.p_id +
            " }}){creatime  complaintImg complaintsReasons p_id taskName childTaskName complaintStatus }"
        );
        this.reason = info.data.jgComplain_ChildTask[0].complaintsReasons; //投诉原因
        console.log(
          "complaintImg",
          JSON.parse(info.data.jgComplain_ChildTask[0].complaintImg)
        );
        for (
          let i = 0;
          i < JSON.parse(info.data.jgComplain_ChildTask[0].complaintImg).length;
          i++
        ) {
          //  console.log("ssds",JSON.parse(info.data.jgComplain_ChildTask[0].complaintImg)[i])
          this.image.push(
            this.api.LoginURL.concat() +
              JSON.parse(info.data.jgComplain_ChildTask[0].complaintImg)[i]
          );
        }
        // console.log("this.image", this.image);
      } else {
        this.tsyy = false; //显示投诉原因
        this.p_id = 0;
      }
    },
    //  斑马纹
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    }
  }
};
</script>
<style scoped>
.tyss {
  width: 100%;
  text-align: center;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  line-height: 48px;
}
/* 数字字符串换行 */
.huanghang {
  word-wrap: break-word;
  word-break: normal;
  overflow: hidden;
  /* width: 100%; */
}
.content {
  width: 100%;
  /* border: 1px solid red; */
  display: inline-block;
}
.zsjg {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 28px;
  /* border: 1px solid red; */
  height: 28px;
}
.shzt {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  line-height: 30px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.prev {
  width: 99%;
  text-align: right;
  height: 40px;
  /* line-height: 40px; */
  margin-top: 10px;
  /* padding-right: 2%;
  border: 1px solid red; */
}
.fontsize {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  margin-bottom: 20px;
}
.reason {
  width: 650px;
  /* height: 180px; */
  display: inline-block;
  border: 1px solid rgb(184, 183, 183);
  margin-left: 10px;
  padding: 15px;
}
.tsyy {
  width: 700px;
  display: inline-block;
  /* border: 1px solid blue; */
}
.width_image {
  width: 700px;
  /* border: 1px solid red; */
}
.DT_image {
  width: 160px;
  height: 160px;
  max-width: 160px;
  max-height: 160px;
  margin-right: 10px;
  margin-left: 10px;
}
/* 弹框 */
.tankuang {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shensu {
  width: 445px;
  height: 236px;
  background-color: #fdfdfd;
  border: 1px solid #2e2e2e;
  /* position: relative; */
  /* z-index: -1; */
}
.heyue_top {
  width: 100%;
  /* height: 18px; */
  padding: 10px 0px 0px 0px;
  /* border: 1px solid red; */
  display: inline-block;
  text-align: right;
} /* 引用 */
.yin1 {
  display: inline-block;
  background-image: url("~@/assets/quxiao.png");
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: 25px 25px;
  /* border: 1px solid #dcdcdc; */
  border-radius: 5%;
  cursor: pointer;
  margin-right: 10px;
}
textarea {
  padding: 0;
  border: 1px solid #000000;
  margin: 0;
  width: 280px;
  height: 80px;
  font-size: 14px;
}
textarea:focus {
  outline: none;
  /* border: 1px solid #9ac1f5; */
  /* box-shadow: 1px 2px 13px 0px rgba(11, 97, 226, 0.44); */
  /* border-width :0 0 1px 0 ; */
}
.yuanyin {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  height: 80px;
  /* line-height: 30px; */
  /* border: 1px solid red; */
  /* display: inline-block; */
  width: 88%;
  /* margin: auto; */
  padding-left: 50px;
  /* padding-right: 30px; */
}
.xinhao {
  position: relative;
  top: -30px;
  left: -10px;
}
.el-table::before {
  z-index: auto;
  /* background-color: red; */
}
.bottoms {
  width: 100%;
  height: 40px;
  /* border:1px solid red; */
  display: inline-block;
  text-align: center;
  margin-top: 20px;
  /* background-color: #d8d8d8ee; */
}
</style>